/* You can add global styles to this file, and also import other style files */
@import url("@scania/tegel/dist/tegel/tegel.css");
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.table-scroll {
  overflow-x: auto;
}

.disabled {
  pointer-events: none;
}
button:disabled {
  pointer-events: none;
}

tds-button button.disabled {
  pointer-events: none;
}

.readonly {
  /* opacity: 0.7; */
  color: gray;
  cursor: not-allowed;
}

@media (max-width: 800px) {
  html {
    overflow-x: hidden !important;
  }
  body {
    overflow-x: hidden !important;
  }
}
